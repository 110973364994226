import { QueryClient, useInfiniteQuery, useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Params } from 'react-router-dom'

import { axiosInstance } from '../axios'
import {
  ApplicationFilters,
  BenefitsApplicationsParams,
  BenefitsParams,
  CompaniesAggregations,
  YearsAggregations,
} from '../types'
import {
  ApplicationChangeRecordCreate,
  Benefit,
  BenefitApplication,
  BenefitApplicationChangeRecord,
  BenefitApplicationChangeRecordCreate,
  BenefitApplicationCreateInput,
  BenefitApplicationCreateOutput,
  BenefitApplicationUpdate,
  BenefitCreate,
  BenefitReportRow,
  BenefitType,
  BenefitUpdate,
  ChangeRecord,
  ChangeRecordsCreate,
  CitySchema,
  CompanySchema,
  DaysLeft,
  DepartmentSchema,
  Document,
  EmployeeIdRoleSchema,
  EmployeeIdSchema,
  EmployeeSchema,
  EmployeeVacationScheduleWithChangeRecord,
  HTTPValidationError,
  PositionSchema,
  Status,
  VacationApplication,
  VacationApplicationCreate,
  VacationApplicationExceptionSchema,
  VacationApplicationResponse,
  VacationApplicationUpdate,
  VacationSchedule,
  VacationScheduleExceptionsArraySchema,
  VacationScheduleExceptionsDictArraySchema,
  VacationSchedulesWithAggregation,
  VacationTimePeriodCreate,
} from '../types/swagger/api.dto'
import { getAttachmentNameFromHeader } from '../utils/get-attachment-name-from-header/get-attachment-name-from-header'

export const URI = {
  vacations: '/vacations/',
  vacationsSchedules: '/vacation-schedules',
  process: '/vacation-schedules/process',
  years: '/vacation-schedules/years',

  applications: '/vacation-applications',
  applicationsDetail: (applicationId: string) => `/vacation-applications/${applicationId}`,
  applicationsDoc: `/vacation-applications/document`,
  applicationsDocDetail: (docId: number) => `/vacation-applications/document/${docId}`,
  applicationProcess: 'vacation-applications/process',

  daysLeft: '/vacations/days_left',
  daysLeftReport: 'vacations/reports/days_left',

  departments: '/departments',

  getToken: '/get_token',

  me: '/me',
  employees: '/employees',
  events: '/events',

  benefits: '/benefits',
  createBenefit: '/benefits',
  benefitsTypes: '/benefits/types',
  benefitsDetail: (benefitId: number | string) => `/benefits/${benefitId}`,
  benefitsApplications: '/benefits/applications',
  benefitsApplicationDetail: (applicationId: string) => `/benefits/applications/${applicationId}`,
  benefitApplicationProcess: '/benefits/applications/process',
  benefitsReports: '/benefits/reports/all',
  benefitsReportsData: '/benefits/reports/all/preview',

  companies: '/companies',
  positions: '/positions',
  cities: '/cities',
}

export function useApplications(filters?: Partial<ApplicationFilters>) {
  return useQuery<Array<VacationApplication>>({
    queryKey: ['applications', filters],
    queryFn: () => axiosInstance.get(URI.applications, { params: filters }).then(({ data }) => data),
  })
}

export function useApplicationsDetail(applicationId?: string | null) {
  return useQuery<VacationApplication>({
    queryKey: ['applications', applicationId],
    queryFn: () => axiosInstance.get(URI.applicationsDetail(applicationId!)).then(({ data }) => data),
    enabled: !!applicationId,
  })
}

export type DepartmentsParams = Partial<{
  companyId: string
  departmentId: string
}>

export function useDepartments(params?: DepartmentsParams) {
  return useQuery({
    queryKey: ['departments', params],
    queryFn: () => axiosInstance.get<DepartmentSchema[]>(URI.departments, { params }).then(({ data }) => data),
    enabled: Boolean(params?.companyId) || Boolean(params?.departmentId),
  })
}

export function useInfiniteDepatrments(initialDepartmentId: string | null) {
  return useInfiniteQuery({
    queryKey: ['departments'],
    queryFn: ({ pageParam: departmentId }) =>
      axiosInstance.get<DepartmentSchema[]>(URI.departments, { params: { departmentId } }).then(({ data }) => data),
    initialPageParam: initialDepartmentId,
    getNextPageParam: (lastPage) => lastPage[0].parentDepartment,
    enabled: initialDepartmentId !== null,
  })
}

export function useYears() {
  return useQuery({
    queryKey: ['years'],
    queryFn: () => axiosInstance.get<number[]>(URI.years).then(({ data }) => data),
  })
}

export type VacationSchedulesParams = Partial<{
  employeeId: string
  status: Status
  departmentId: string
  companyId: string
  year: number
  fullName: string
  limit: number
  offset: number
}>

export function useVacationSchedules(params?: VacationSchedulesParams, enabled?: boolean) {
  return useQuery({
    queryKey: ['vacationSchedules', params],
    queryFn: () =>
      axiosInstance.get<VacationSchedulesWithAggregation>(URI.vacationsSchedules, { params }).then(({ data }) => data),
    enabled,
  })
}

export function useVacationSchedulesCreate() {
  return useMutation<VacationSchedule, AxiosError<VacationScheduleExceptionsArraySchema>, VacationTimePeriodCreate[]>({
    mutationFn: (payload) => axiosInstance.post(URI.vacationsSchedules, payload).then(({ data }) => data),
  })
}

export function useVacationSchedulesUpdate() {
  return useMutation<
    VacationSchedule,
    AxiosError<VacationScheduleExceptionsArraySchema>,
    { timePeriods: VacationTimePeriodCreate[]; vacationScheduleId: number }
  >({
    mutationFn: (payload) =>
      axiosInstance
        .put(`${URI.vacationsSchedules}/${payload.vacationScheduleId}`, payload.timePeriods)
        .then(({ data }) => data),
  })
}

export function useVacationSchedulesDelete() {
  return useMutation({
    mutationFn: (vacationScheduleId: number) =>
      axiosInstance.delete(`${URI.vacationsSchedules}/${vacationScheduleId}`).then(({ data }) => data),
  })
}

export const getEmployeeVacationSchedule = (vacationScheduleId: string) => ({
  queryKey: ['employeeVacationSchedule', vacationScheduleId],
  queryFn: () =>
    axiosInstance
      .get<EmployeeVacationScheduleWithChangeRecord>(`${URI.vacationsSchedules}/${vacationScheduleId}`)
      .then(({ data }) => data),
})

export function selectedEmployeeScheduleLoader(queryClient: QueryClient) {
  return async ({ params }: { params: Params<'vacationScheduleId'> }) => {
    const data = await queryClient.ensureQueryData(getEmployeeVacationSchedule(params?.vacationScheduleId as string))
    return data
  }
}

export function useEmployeeVacationSchedule(vacationScheduleId: string) {
  return useQuery(getEmployeeVacationSchedule(vacationScheduleId))
}

export function useGetToken() {
  return useMutation({
    mutationFn: (payload: EmployeeIdSchema) =>
      axiosInstance.post<{ token: string }>(URI.getToken, payload).then(({ data }) => data),
  })
}

export function useGetMe() {
  return useQuery({
    queryKey: ['me'],
    queryFn: () => axiosInstance.get<EmployeeIdRoleSchema>(URI.me).then((res) => res.data),
    // запрашиваем один раз
    staleTime: Infinity,
    throwOnError: true,
  })
}

export function useUpdateProcess() {
  return useMutation<ChangeRecord[], AxiosError<VacationScheduleExceptionsDictArraySchema>, ChangeRecordsCreate>({
    mutationFn: (payload) => axiosInstance.put(URI.process, payload).then(({ data }) => data),
  })
}

function getCompanies(companyId?: string) {
  return {
    queryKey: ['companies', companyId],
    queryFn: () =>
      axiosInstance
        .get<CompanySchema[]>(URI.companies, {
          params: {
            companyId,
          },
        })
        .then((res) => res.data),
  }
}

export function useCompanies(companyId?: string) {
  return useQuery(getCompanies(companyId))
}

export function companiesLoader(queryClient: QueryClient) {
  return async ({ params }: { params: Params<'division'> }) => {
    const query = getCompanies(params.division)
    return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
  }
}

export function useCompaniesAggregations(params?: CompanySchema[]) {
  return useQueries({
    queries:
      params?.map(({ id, name }) => ({
        queryKey: ['vacationSchedules', { companyId: id }],
        queryFn: () =>
          axiosInstance
            .get<VacationSchedulesWithAggregation>(URI.vacationsSchedules, { params: { companyId: id } })
            .then(({ data }) => ({ ...data.aggregation, name, id })),
      })) ?? [],
    combine: (results) => ({
      data: results.filter((res) => res.data).map(({ data }) => data as CompaniesAggregations),
      isLoading: results.some((result) => result.isLoading),
      isSuccess: results.length > 0 ? results.every((result) => result.isSuccess) : true,
    }),
  })
}

export function useYearsAggregations(years?: number[]) {
  return useQueries({
    queries:
      years?.map((year) => ({
        queryKey: ['vacationSchedules', { year }],
        queryFn: () =>
          axiosInstance.get<VacationSchedulesWithAggregation>(URI.vacationsSchedules, { params: { year } }),
        select: (data: { data: VacationSchedulesWithAggregation }) => {
          return { ...data.data.aggregation, year }
        },
      })) ?? [],
    combine: (results) => {
      return {
        data: results.filter((res) => res.data).map(({ data }) => data as YearsAggregations),
        isLoading: results.some((result) => {
          return result.isLoading
        }),
        isSuccess:
          results.length > 0
            ? results.every((result) => {
                return result.isSuccess
              })
            : true,
      }
    },
  })
}

type EmployeesFilters = Partial<{
  employeeId: string | null
  fullName: string
  departmentId: string
  companyId: string
}>

export function useEmployees(filters: EmployeesFilters, enabled: boolean = true) {
  return useQuery<EmployeeSchema[]>({
    queryKey: ['employees', filters],
    queryFn: () => axiosInstance.get(URI.employees, { params: filters }).then(({ data }) => data),
    enabled,
  })
}

export function useMultipleEmployees(ids: (EmployeeIdSchema['id'] | null)[]) {
  return useQueries({
    queries: ids.map((employeeId) => ({
      queryKey: ['employees', { employeeId }],
      queryFn: () =>
        axiosInstance.get<EmployeeSchema[]>(URI.employees, { params: { employeeId } }).then(({ data }) => data),
      enabled: employeeId !== undefined,
    })),
    combine: (results) => ({
      data: results.map((result) => result.data?.[0]),
      isPending: results.length > 0 ? results.some((result) => result.isPending) : false,
      isSuccessArr: results.map((result) => result.isSuccess),
    }),
  })
}

export function useDaysLeft(employeeId?: string) {
  return useQuery({
    queryKey: ['days-left', employeeId],
    queryFn: () => axiosInstance.get<DaysLeft>(URI.daysLeft, { params: { employeeId } }).then(({ data }) => data),
    enabled: employeeId !== undefined,
  })
}

export function useDaysLeftReport(
  params: Partial<{ reportEndDate: string; departmentIds: string[]; companyId: string }>,
) {
  return useQuery({
    queryKey: ['reports-days-left', params],
    queryFn: () =>
      axiosInstance.get<string>(URI.daysLeftReport, { params, responseType: 'blob' }).then(
        (res) =>
          new File([res.data], getAttachmentNameFromHeader(res.headers), {
            type: res.headers['content-type'] as string,
          }),
      ),
    enabled: false,
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
  })
}

export function useApplicationCreate() {
  return useMutation<unknown, AxiosError<VacationApplicationExceptionSchema>, VacationApplicationCreate>({
    mutationFn: (payload) => axiosInstance.post(URI.applications, payload).then(({ data }) => data),
  })
}

export function useApplicationUpdate() {
  return useMutation<
    VacationApplicationResponse,
    AxiosError<VacationApplicationExceptionSchema>,
    VacationApplicationUpdate & {
      applicationId: string
    }
  >({
    mutationFn: (payload) => {
      const { applicationId, ...rest } = payload

      return axiosInstance.put(URI.applicationsDetail(applicationId), rest).then(({ data }) => data)
    },
  })
}

export function useAttachment(documentId?: Document['id'] | null) {
  return useQuery({
    enabled: !!documentId,
    queryKey: ['document', documentId],
    queryFn: () =>
      axiosInstance
        .get<string>(URI.applicationsDocDetail(documentId!), {
          responseType: 'blob',
        })
        .then(
          (response) =>
            new File([response.data], getAttachmentNameFromHeader(response.headers), {
              type: response.headers['content-type'] as string,
            }),
        ),
    // документы перезапрашивать нет смысла
    staleTime: Infinity,
  })
}

export function useAttachments(documentIds?: Document['id'][] | null, enabled: boolean = true) {
  return useQueries({
    queries:
      documentIds?.map((documentId) => ({
        queryKey: ['document', documentId],
        queryFn: () =>
          axiosInstance
            .get<string>(URI.applicationsDocDetail(documentId), {
              responseType: 'blob',
            })
            .then(
              (response) =>
                new File([response.data], getAttachmentNameFromHeader(response.headers), {
                  type: response.headers['content-type'] as string,
                }),
            ),
        // документы перезапрашивать нет смысла
        staleTime: Infinity,
        enabled,
      })) ?? [],
    combine: (results) => ({
      data: results.map((result) => result.data as File),
      isPending: results.map((result) => result.isPending),
      isLoading: results.map((result) => result.isLoading),
      isSuccess: results.map((result) => result.isSuccess),
    }),
  })
}

export function useAttachmentsUpload() {
  return useMutation<Document['id'][], unknown, { fileList: FileList | null }>({
    mutationFn: (payload) =>
      Promise.all<Document>(
        Array.from(payload.fileList ?? []).map((file) => {
          const formData = new FormData()
          formData.append('file', file)

          return axiosInstance
            .post(URI.applicationsDoc, formData, {
              params: {
                // отправляем дефолтный
                document_type: 'application-file',
              },
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((res) => res.data)
        }),
      ).then((data) => data.map((v) => v.id)),
  })
}

export function useBenefits(params?: BenefitsParams) {
  return useQuery({
    queryKey: ['benefits', params],
    queryFn: () => axiosInstance.get<Benefit[]>(URI.benefits, { params }).then(({ data }) => data),
  })
}

export function useBenefit(benefitId?: number, enabled: boolean = true) {
  return useQuery({
    queryKey: ['benefit', benefitId],
    queryFn: () => axiosInstance.get<Benefit>(`${URI.benefits}/${benefitId}`).then(({ data }) => data),
    enabled,
  })
}

export function useBenefitsApplications(params?: BenefitsApplicationsParams, enabled: boolean = true) {
  return useQuery<BenefitApplication[]>({
    queryKey: ['benefitsApplications', params],
    queryFn: () =>
      axiosInstance.get<BenefitApplication[]>(URI.benefitsApplications, { params }).then(({ data }) => data),
    enabled,
  })
}

export function useBenefitApplicationCreate() {
  return useMutation<BenefitApplicationCreateOutput, AxiosError<unknown>, BenefitApplicationCreateInput>({
    mutationFn: (payload) => axiosInstance.post(URI.benefitsApplications, payload).then(({ data }) => data),
  })
}

export function useBenefitApplicationUpdate() {
  return useMutation<
    BenefitApplicationCreateOutput,
    AxiosError<unknown>,
    BenefitApplicationUpdate & Pick<Benefit, 'id'>
  >({
    mutationFn: ({ id, ...payload }) =>
      axiosInstance.put(URI.benefitsApplicationDetail(id.toString()), payload).then(({ data }) => data),
  })
}

function getBenefitApplication(applicationId?: string) {
  return {
    enabled: !!applicationId,
    queryKey: ['benefitsApplication', applicationId],
    queryFn: () =>
      axiosInstance
        .get<BenefitApplication>(URI.benefitsApplicationDetail(applicationId!), {
          params: {
            benefitApplicationId: Number(applicationId),
          },
        })
        .then(({ data }) => data),
  }
}

export function useBenefitsApplication(applicationId?: string) {
  return useQuery(getBenefitApplication(applicationId))
}

export function benefitApplicationDetailLoader(queryClient: QueryClient) {
  return async ({ params }: { params: Params<'applicationId'> }) => {
    const query = getBenefitApplication(params.applicationId)

    return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
  }
}

export function useBenefitsApplicationProcess() {
  return useMutation<
    BenefitApplicationChangeRecord[],
    AxiosError<HTTPValidationError>,
    BenefitApplicationChangeRecordCreate
  >({
    mutationFn: (payload) => axiosInstance.put(URI.benefitApplicationProcess, payload).then(({ data }) => data),
  })
}

export function getBenefitTypes() {
  return {
    queryKey: ['benefitTypes'],
    queryFn: () => axiosInstance.get<BenefitType[]>(URI.benefitsTypes).then(({ data }) => data),
    // Данные по типам льгот постоянные - их невозможно поменять
    staleTime: Infinity,
  }
}

export function benefitTypeDetailLoader(queryClient: QueryClient) {
  return async ({ params }: { params: Params<'benefitTypeId'> }) => {
    const query = getBenefitTypes()

    const data: BenefitType[] = queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))

    return data.find((el) => el.id === Number(params.benefitTypeId))
  }
}

export function useBenefitTypes() {
  return useQuery<BenefitType[]>(getBenefitTypes())
}

type BenefitReportParams = { reportStartDate: string; reportEndDate: string; benefitTypeId: number }

export function useBenefitsReport(params: BenefitReportParams) {
  return useQuery({
    queryKey: ['benefits-report', params],
    queryFn: () =>
      axiosInstance.get<string>(URI.benefitsReports, { params, responseType: 'blob' }).then(
        (res) =>
          new File([res.data], getAttachmentNameFromHeader(res.headers), {
            type: res.headers['content-type'] as string,
          }),
      ),
    enabled: false,
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
  })
}

export function useBenefitTypeReportData(params: Partial<BenefitReportParams>) {
  return useQuery({
    queryKey: ['benefit-report-data', params],
    queryFn: () => axiosInstance.get<BenefitReportRow[]>(URI.benefitsReportsData, { params }).then((res) => res.data),
    enabled: Object.values(params).every(Boolean),
    // если ничего не найдено, бэк возвращает 404, а не пустой массив, поэтому отключил ретраи
    retry: false,
  })
}

export function usePositions() {
  return useQuery({
    queryKey: ['positions'],
    queryFn: () => axiosInstance.get<PositionSchema[]>(URI.positions).then(({ data }) => data),
    staleTime: Infinity,
  })
}

export function useCities() {
  return useQuery({
    queryKey: ['cities'],
    queryFn: () => axiosInstance.get<CitySchema[]>(URI.cities).then(({ data }) => data),
    staleTime: Infinity,
  })
}

export function useBenefitCreate() {
  return useMutation<unknown, AxiosError<unknown>, BenefitCreate>({
    mutationFn: (payload) => axiosInstance.post(URI.createBenefit, payload).then(({ data }) => data),
  })
}

export function useBenefitUpdate() {
  return useMutation<unknown, AxiosError<unknown>, BenefitUpdate & Pick<Benefit, 'id'>>({
    mutationFn: ({ id, ...payload }) => axiosInstance.put(URI.benefitsDetail(id), payload).then(({ data }) => data),
  })
}

function getBenefitDetail(benefitId?: string) {
  return {
    enabled: !!benefitId,
    staleTime: 60,
    queryKey: ['benefits', benefitId],
    queryFn: () => axiosInstance.get<Benefit>(URI.benefitsDetail(benefitId!)).then(({ data }) => data),
  }
}

export function useBenefitsDetail(benefitId?: string) {
  return useQuery(getBenefitDetail(benefitId))
}

export function benefitDetailLoader(queryClient: QueryClient) {
  return async ({ params }: { params: Params<'benefitId'> }) => {
    const query = getBenefitDetail(params.benefitId)

    return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
  }
}

export function useDeleteBenefit() {
  const queryClient = useQueryClient()

  return useMutation<unknown, unknown, string>({
    mutationFn: (benefitId) => axiosInstance.delete(URI.benefitsDetail(benefitId)).then(({ data }) => data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['benefits'] })
    },
  })
}

export function useApplicationProcess() {
  return useMutation<VacationApplicationResponse, unknown, ApplicationChangeRecordCreate>({
    mutationFn: (payload) => axiosInstance.put(URI.applicationProcess, payload).then((res) => res.data),
  })
}
