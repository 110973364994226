import { notifications } from '@mantine/notifications'
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query'
import { isAxiosError } from 'axios'
import { Translation } from 'react-i18next'

import { Icon } from 'src/shared/ui-kit/icon'
import notificationClasses from 'src/shared/ui-kit/notifications/ui/notifications.module.css'

import { theme } from '../mantine/ui/theme'

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (err) => {
      // не обрабатываем ошибки с эндпоинтов, когда нет данных и получаем 404
      if (isAxiosError(err) && err.response?.status === 404 && err.response?.data?.detail === 'NO_DATA') {
        return
      } else {
        notifications.show({
          title: <Translation>{(t) => t('error.default')}</Translation>,
          message: '',
          icon: <Icon name="error_outlined" fill={theme.colors?.red?.[0]} />,
          classNames: notificationClasses,
          variant: 'error',
        })
      }
    },
  }),
  mutationCache: new MutationCache({
    onError: () =>
      notifications.show({
        title: <Translation>{(t) => t('error.default')}</Translation>,
        message: '',
        icon: <Icon name="error_outlined" fill={theme.colors?.red?.[0]} />,
        classNames: notificationClasses,
        variant: 'error',
      }),
  }),
  defaultOptions: {
    queries: {
      throwOnError: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      throwOnError: false,
    },
  },
})
